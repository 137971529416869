<template>
  <div class="news">
    <img v-for="(item,index) in pageAd" :key="index" :src="serverUrl + item.savePath" alt="">
    <div class="tabList">
      <div class="tabs-wrap">
        <div class="tabs">
          <div class="item" :class="tabIndex === index ? 'active' : ''" v-for="(item,index) in tabs"
               :key="index" @click="selectTabItem(index)">{{ item }}
          </div>
        </div>
      </div>

      <div class="tabs-content">
        <div class="first-tab" v-if="tabIndex === 0">
          <div class="first-tab">
            <p class="title">公司动态</p>
          </div>
          <div class="case-content">
            <div class="news-item" v-for="(item, index) in dynamicList" :key="index">
              <div class="img-left">
                <img :src="serverUrl + item.coverImage" alt="">
              </div>
              <div class="right-content">
                <div class="right-title">{{ item.title }}</div>
                <div class="right-time">发布时间：{{ item.createTime }}</div>
                <div class="right-text">{{ item.description }}</div>
                <div class="more" @click="toMore(item)">MORE</div>
              </div>
            </div>
          </div>
          <div class="pagination">
            <el-pagination
                @size-change="(val)=>handleSizeChange('dynamic',val)"
                @current-change="(val)=>handleCurrentChange('dynamic',val)"
                :current-page="dynamicQueryCondition.pageNum"
                :page-size="dynamicQueryCondition.pageSize"
                :page-sizes="[10,20]"
                layout="total, prev, pager, next"
                :total="dynamicQueryCondition.total">
            </el-pagination>
          </div>
        </div>

        <div class="secend-tab" v-if="tabIndex === 1">
          <div class="first-tab">
            <p class="title">行业资讯</p>
          </div>
          <div class="case-content">
            <div class="news-item" v-for="(item, index) in newsList" :key="index">
              <div class="img-left">
                <img :src="serverUrl + item.coverImage" alt="">
              </div>
              <div class="right-content">
                <div class="right-title">{{ item.title }}</div>
                <div class="right-time">发布时间：{{ item.createTime }}</div>
                <div class="right-text">{{ item.description }}</div>
                <div class="more" @click="toMore(item)">MORE</div>
              </div>
            </div>
          </div>

          <div class="pagination">
            <el-pagination
                @size-change="(val)=>handleSizeChange('news',val)"
                @current-change="(val)=>handleCurrentChange('news',val)"
                :current-page="newsQueryCondition.pageNum"
                :page-size="newsQueryCondition.pageSize"
                :page-sizes="[10,20]"
                layout="total, prev, pager, next"
                :total="newsQueryCondition.total">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getAd, getArts} from "@/api/api";
import {serverUrl} from "@/utils/config";
export default {
  data() {
    return {
      serverUrl: serverUrl,
      activeName: "first",
      currentPage: 1, //初始页
      pageSize: 5,    //    每页的数据
      dataList: [],
      paginationList: [],
      tabs: ["公司动态", "行业资讯"],
      tabIndex: 0,
      dynamicList: [],  // 动态,
      dynamicQueryCondition: {
        categoryId: 6,
        pagination: true,
        containsContent: false,
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      newsList: [],  // 资讯,
      newsQueryCondition: {
        categoryId: 13,
        pagination: true,
        containsContent: false,
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      pageAd: []
    };
  },
  activated() {
    this.getDynamicList();
    this.getNewsList();
    this.getPageAd();
    let type = this.$route.query.type;
    if(type !== undefined){
      this.tabIndex = Number(type);
    }
  },
  methods: {
    getPageAd(){
      getAd({
        code: 'news_ad'
      }).then((resp) => {
        let {code, data} = resp;
        if (code === 0) {
          this.pageAd = data;
        }
      })
    },
    getDynamicList(){
      getArts(this.dynamicQueryCondition).then((resp) => {
        let {code, data} = resp;
        let { rows,total } = data;
        if (code === 0) {
          this.dynamicList = rows;
          this.dynamicQueryCondition.total = total;
        }
      })
    },
    getNewsList(){
      getArts(this.newsQueryCondition).then((resp) => {
        let {code, data} = resp;
        let { rows,total } = data;
        if (code === 0) {
          this.newsList = rows;
          this.newsQueryCondition.total = total;
        }
      })
    },
    selectTabItem(tabIndex) {
      this.tabIndex = tabIndex;
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleSizeChange: function (type,size) {
      switch (type){
        case "dynamic":
          this.dynamicQueryCondition.pageSize = size;
          this.getDynamicList();
          break;
        case "news":
          this.newsQueryCondition.pageSize = size;
          this.getNewsList();
          break;
      }
    },
    handleCurrentChange (type,currentPage) {
      switch (type){
        case "dynamic":
          this.dynamicQueryCondition.pageNum = currentPage;
          this.getDynamicList();
          break;
        case "news":
          this.newsQueryCondition.pageNum = currentPage;
          this.getNewsList();
          break;
      }
    },
    toMore(data) {
      this.$router.push({
        path: '/new_detail', query: {
          id: data.id,
          backPage: 'news',
          backTitle: '新闻动态',
        }
      });
    }
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
